
    import { defineComponent, reactive, PropType, ref, watch } from 'vue';
    import { ColumnProps } from 'ant-design-vue/es/table/interface';
    import { message } from "ant-design-vue";
    import { QueryParams } from '../ControllingRoleApprove/ProgramHome/types'

    import { deleteProgramCollection } from '@/API/approve'
    type Key = ColumnProps['key'];


    const columnsAll = [
        {
            title: 'BU',
            dataIndex: 'bu',
            key: 'bu',
            width: 80,
            slots: { customRender: 'bu' },
            ellipsis: true,
        },
        {
            title: 'Make',
            dataIndex: 'make',
            key: 'make',
            slots: { customRender: 'make' },
            width: 80,
            ellipsis: true
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
            slots: { customRender: 'brand' },
            width: 100,
            ellipsis: true
        },
        {
            title: 'Type Class',
            dataIndex: 'typeClass',
            key: 'typeClass',
            slots: { customRender: 'typeClass' },
            width: 100,
            ellipsis: true
        },
        {
            title: 'Program Period',
            dataIndex: 'period',
            key: 'period',
            slots: { customRender: 'period' },
            ellipsis: true
        },
        {
            title: 'Program Status',
            dataIndex: 'status',
            key: 'status',
            slots: { customRender: 'status' },
            width: 160,
            ellipsis: true
        },
        {
            title: 'Program Name',
            dataIndex: 'name',
            key: 'name',
            width: 180,
            ellipsis: true
        },
        {
            title: 'Program Code',
            dataIndex: 'code',
            key: 'code',
            width: 120,
            ellipsis: true
        },
        {
            title: 'Operation',
            key: 'operation',
            slots: { customRender: 'operation' },
        }
    ];

    export default defineComponent({
        name: "ProgramSearchValueConfirm",
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            dataSource: {
                type: Array as PropType<any[]>,
                default: () => []
            },
            buOptions: {
                type: Array as PropType<any[]>,
                default: () => []
            }
        },
        // emits: ['update:visible', 'confirm'],
        setup(props, content) {
            const columns = ref<any[]>([]);
            const tableData = ref()
            
            const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: any[]; onChange: Function; fixed: boolean }>({
                onChange: (selectedRowKeys: Key[],selectedRowData: any[] ) => {
                    rowSelection.selectedRowKeys = selectedRowKeys;
                    rowSelection.selectedRowData = selectedRowData;
                },
                selectedRowData: [],
                selectedRowKeys: [],
                fixed: true
            })

            const handlerConfirm = (): void => {
                if (rowSelection.selectedRowData.length === 0) {
                    message.error('请选择');
                    return;
                }
                if (rowSelection.selectedRowData.length > 1) {
                    message.error('请选择一条');
                    return;
                }
                const obj = rowSelection.selectedRowData[0]
                obj.bu = obj.bu.map((itemval: string) => {
                    for (const keyval of props.buOptions) {
                        if (itemval === keyval.name) {
                            return keyval.id
                        }
                    }
                })
                content.emit('confirm', obj);
                content.emit('update:visible', false);
                rowSelection.selectedRowData = [];
                rowSelection.selectedRowKeys = [];
            }

            const handleDelete = (record: any, index: number) => {
                deleteProgramCollection({id: [record.id]}).then(res => {
                    tableData.value.splice(index, 1)
                    // content.emit('update:visible', false);
                })
            }

            watch(() => props.visible, (value) => {
                if (value) {
                    tableData.value = props.dataSource.map((item: any) => {
                        item.bu = item.bu.map((itemval: string) => {
                            for (const keyval of props.buOptions) {
                                if (itemval === keyval.id) {
                                    return keyval.name
                                }
                            }
                        })
                        
                        return item
                    })
                    // const keys = Object.keys(props.dataSource[0]);
                    // //item.dataIndex.includes('period') 处理时间
                    // columns.value = columnsAll.filter(item =>  keys.includes(item.dataIndex) || item.dataIndex.includes('period'));
                }
            })

            return {
                columns,
                columnsAll,
                rowSelection,
                handlerConfirm,
                handleDelete,
                tableData
            }
        }
    })
