
    import { defineComponent, reactive, ref, toRefs, onMounted, nextTick } from 'vue';
    import moment from "moment";
    import { message, Modal } from "ant-design-vue";
    import { ColumnProps } from 'ant-design-vue/es/table/interface';
    import Pagination from "@/components/Pagination.vue";
    import TableWrapper from "@/components/TableWrapper.vue";
    import ProgramPreview from "@/views/Approve/components/ProgramPreview.vue";
    import ProgramSearchValueConfirm from '@/views/Approve/components/ProgramSearchValueConfirm.vue';
    import useFetch from "@/hooks/useFetch";
    import useTableHeight from "@/hooks/useTableHeight";
    import useTableRowHighlight from "@/hooks/approve/useTableRowHighlight";
    import ShowCommentsModel from '@/views/Approve/components/ShowCommentsModel.vue'
    import {
        getAllBu,
        getVehicleTypeClass,
        getVehicleBand,
        getVehicleMake,
        getProgramStatus,
        getControllingProgramData,
        controllingConfirmProgram,
        setSearchValue,
        getSearchValue,
        getSubscribedBu,
        getHistoryComments,
        controllingModifyStatus,
        getLog,
        findBuVehicleList
    } from "@/API/approve";
    import { QueryParams, BuItem, ProgramApproveProps, CollectionProps } from '../types';
    import {
        TypeClassItem,
        ProgramStatusItem,
        BrandItem,
        CommentProps
    } from "@/views/Approve/SalesRoleApprove/ProgramHome/types";
    import { useRouter } from "vue-router";
    import { TreeSelect } from 'ant-design-vue';
    import WordingBudget from '@/views/Program/Wording/WordingBudget.vue'
    type Key = ColumnProps['key'];

    const columns = [
        {
            title: 'Program Code',
            dataIndex: 'programCode',
            key: 'programCode',
            width: 180,
            fixed: 'left',
        },
        {
            title: 'Program Name',
            dataIndex: 'programName',
            key: 'programName',
            slots: { customRender: 'programName' },
            width: 240,
            fixed: 'left',
        },
        {
            title: 'Program Status',
            dataIndex: 'programStatus',
            key: 'programStatus',
            width: 140,
            slots: { customRender: 'programStatus' },
            fixed: true
        },
        {
            title: 'Make',
            dataIndex: 'make',
            key: 'make',
            width: 120
        },
        {
            title: 'Program Period From',
            dataIndex: 'programPeriodFrom',
            key: 'programPeriodFrom',
            slots: { customRender: 'programPeriodFrom' },
            width: 160
        },
        {
            title: 'To',
            dataIndex: 'programPeriodTo',
            key: 'to',
            slots: { customRender: 'programPeriodFrom' },
            width: 150
        },
        {
            title: 'Sales Status',
            dataIndex: 'salesStatus',
            key: 'salesStatus',
            width: 120
        },
        {
            title: 'Controlling Status',
            dataIndex: 'controllingStatus',
            slots: { customRender: 'controllingStatus'},
            key: 'controllingStatus',
            width: 130
        },
        {
            title: 'Batch Approve Name',
            dataIndex: 'batchApproveName',
            key: 'batchApproveName',
            width: 160
        },
        {
            title: 'Last Update',
            dataIndex: 'lastUpdatedDate',
            key: 'lastUpdate',
            slots: { customRender: 'lastUpdate' },
            width: 140
        },
        {
            title: 'Status Log',
            dataIndex: 'statusLog',
            key: 'statusLog',
            slots: { customRender: 'statusLog' },
            width: 200
        }
    ];
    interface TreeDataItem {
        value: string;
        key: string;
        title?: string;
        disabled?: boolean;
        children?: TreeDataItem[];
    }
    const SHOW_PARENT = TreeSelect.SHOW_PARENT;
    const qmOptions: TreeDataItem[] = [
        {
            title: 'Q1',
            value: 'Q1',
            key: 'Q1',
            children: [
                {
                    title: '01',
                    value: '01',
                    key: '01',
                },
                {
                    title: '02',
                    value: '02',
                    key: '02',
                },
                {
                    title: '03',
                    value: '03',
                    key: '03',
                },
            ],
        },
        {
            title: 'Q2',
            value: 'Q2',
            key: 'Q2',
            children: [
                {
                    title: '04',
                    value: '04',
                    key: '04',
                },
                {
                    title: '05',
                    value: '05',
                    key: '05',
                },
                {
                    title: '06',
                    value: '06',
                    key: '06',
                },
            ],
        },
        {
            title: 'Q3',
            value: 'Q3',
            key: 'Q3',
            children: [
                {
                    title: '07',
                    value: '07',
                    key: '07',
                },
                {
                    title: '08',
                    value: '08',
                    key: '08',
                },
                {
                    title: '09',
                    value: '09',
                    key: '09',
                },
            ],
        },
        {
            title: 'Q4',
            value: 'Q4',
            key: 'Q4',
            children: [
                {
                    title: '10',
                    value: '10',
                    key: '10',
                },
                {
                    title: '11',
                    value: '11',
                    key: '11',
                },
                {
                    title: '12',
                    value: '12',
                    key: '12',
                },
            ],
        },
    ];
    export default defineComponent({
        name: "ProgramHomePage",
        components: { Pagination, TableWrapper, ProgramPreview, ProgramSearchValueConfirm, ShowCommentsModel, WordingBudget },
        setup() {
            const tableHeightRef = ref()
            const { tableHeight  } = useTableHeight();

            const router = useRouter();
            //wording
            const visible = ref<boolean>(false);
            // 年份
            const year = ref<string>(moment().add('month', 1).format('YYYY'));
            const openYear = ref<boolean>(false);
            const handlerOpenChange = (status: any): void =>{
                if (status) {
                    openYear.value = true;
                }
            }
            const handlerPanelChange = (value: string): void =>{
                const time = moment(value).format('YYYY');
                year.value = time as string;
                openYear.value = false;
            }

            // 季度 月份
            const getDefaultQm = (): string => {
                return 'Q' + moment().add('month', 1).quarter();
            }
            const qm = ref<string[]>([getDefaultQm()]);

            // 折叠控制
            const isOpen = ref(false)

            const handleCollapse = () => {
                isOpen.value = !isOpen.value
                nextTick(() => {
                    const tablePosition = useTableHeight(true, '.pagination-container', true);
                    console.log(tablePosition,'tablePosition');
                    tableHeight.value = tablePosition.tableHeight.value
                })
            }


            // woridng preview
            const visiblePreview = ref(false)

            const programPreviewData = reactive<{ programId: string; programPeriod: string}>({
                programId: '',
                programPeriod: ''
            });
            const commentsObj = reactive<{
                callBackTextVisible: boolean;
                comments: CommentProps[];
                log: string[];
            }>({
                callBackTextVisible: false,
                comments: [],
                log: []
            })

            //setting search value
            const visibleCollection = ref<boolean>(false);

            //setting search value list
            const searchValueList = ref<QueryParams[]>([]);

            

            //typeClass options
            // const { data: typeClassOptions } = useFetch<TypeClassItem[]>({}, [], getVehicleTypeClass);

            //programStatus options
            const { data: programStatusOptions } = useFetch<ProgramStatusItem[]>({type: 'controlling'}, [], getProgramStatus);

            //make
            // const { data: makeOptions } = useFetch<{make: string}[]>({}, [], getVehicleMake);

            //bu

            //query Params
            const queryParams = reactive<QueryParams>({
                bu: [],
                make: [],
                brand: [],
                typeClass: [],
                year: year.value,
                qm: qm.value,
                status: [],
                name: '',
                code: '',
                mine: true
            });

            const { data, pagination, refreshFetch } = useFetch<ProgramApproveProps[]>(queryParams, [], getControllingProgramData, true);

            const { currentClickRowIndex, handlerRowClick } = useTableRowHighlight(visible);

            const filterOption = (input: string, option: any) => {
                return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }

            // const fetchBrand = async (): Promise<void> => {
            //     try {
            //         const result = await getVehicleBand();
            //         Object.assign(brandOptions.value, result);
            //     } catch (e) {
            //         console.log(e);
            //     }
            // }
            // const { data: buOptions } = useFetch<BuItem[]>({}, [], getSubscribedBu);
            const buOptions = ref()
            const makeOptions = ref()
            //brand options
            const brandOptions = ref();
            const typeClassOptions = ref()
            // 获取bu make brand typeclass联动的接口
            const initVehicleBu = () => {
                const params = {
                    target: 'buId'
                }
                findBuVehicleList(params).then(res => {
                    buOptions.value = res
                })
            }   

            const intoFull = (target: string) => {
                switch (target) {
                    case 'buId':
                        buOptions.value = []
                        break;
                    case 'make':
                        makeOptions.value = []
                        break;
                    case 'brand':
                        brandOptions.value = []
                        break;
                    case 'typeClass':
                        typeClassOptions.value = []
                        break;
                }
            } 

            // 获取焦点获取数据
            const handleFocus = (target: string) => {
                const params = {
                    buId: queryParams.bu,
                    brand: queryParams.brand,
                    make: queryParams.make,
                    typeClass: queryParams.typeClass,
                    target
                }
                intoFull(target)
                findBuVehicleList(params).then(res => {
                    switch (target) {
                        case 'buId':
                            buOptions.value = res
                            break;
                        case 'make':
                            makeOptions.value = res
                            break;
                        case 'brand':
                            brandOptions.value = res
                            break;
                        case 'typeClass':
                            typeClassOptions.value = res
                            break;
                    }
                })
            }

            // 显示comments
            const handleShowComments = (programId: string, programStatus: string) => {
                getHistoryComments({ params: { programId: programId, programStatus: programStatus}}).then(res => {
                    commentsObj.comments = res.comment
                    if (res.comment.length > 0) {
                        commentsObj.callBackTextVisible = true
                    } else {
                        message.warning('No Comments!')
                    }
                })
            }
             const handleShowLog = (programId: string) => {
                getLog({ params: { programId: programId}}).then(res => {
                    commentsObj.log = res
                })
            }
            
            const rowSelection = reactive<{ selectedRowKeys: Key[]; onChange: Function }>({
                onChange: (selectedRowKeys: Key[], selectedRows: ProgramApproveProps[]) => {
                    rowSelection.selectedRowKeys = selectedRowKeys;
                },
                selectedRowKeys: []
            })

            const resetTableSelectData = (): void => {
                rowSelection.selectedRowKeys = [];
            }

            const handlerResetBen = (): void => {
                Object.assign(queryParams, {
                    bu: [],
                    make: [],
                    brand: [],
                    typeClass: [],
                    year: moment().add('month', 1).format('YYYY'),
                    qm: [getDefaultQm()],
                    status: [],
                    name: '',
                    code: '',
                    mine: true
                })
                year.value = moment().add('month', 1).format('YYYY')
                qm.value = [getDefaultQm()]
                resetTableSelectData();
                pagination.page = 1;
                refreshFetch();
            }

            //分页事件处理
            const handlerPaginationChange = (page: number): void => {
                pagination.page = page;
                refreshFetch();
                resetTableSelectData();
            }

            const handlerShowSizeChange = (current: number, pageSize: number): void => {
                pagination.pageSize = pageSize;
                Object.assign(pagination, { page: 1, pageSize: pageSize });
                refreshFetch();
                resetTableSelectData();
            }


            const handlerReturnClick = (): void => {
                if (rowSelection.selectedRowKeys.length == 0) {
                    message.error('Please select programs');
                    return;
                }
                const program = data.value.filter((item, index) => rowSelection.selectedRowKeys.includes(index)).map(item => ({id: item.programId }));
                Modal.confirm({
                    title: '提示',
                    content: 'Return Program to Sales',
                    onOk() {
                        return new Promise<void>((resolve, reject) => {
                            controllingConfirmProgram({ confirm: false, program }).then(data => {
                                refreshFetch(1);
                                resetTableSelectData();
                                message.success('Returned successfully')
                                resolve();
                            }).catch(e => {
                                reject(e);
                            })
                        })
                    },
                    onCancel() {
                        console.log('Cancel');
                    }
                });
            }

            const handlerConfirmClick = (): void => {
                    if (rowSelection.selectedRowKeys.length == 0) {
                        message.error('Please select programs');
                        return;
                    }
                    const program = data.value.filter((item, index) => rowSelection.selectedRowKeys.includes(index)).map(item => ({id: item.programId }));
                    Modal.confirm({
                        title: '提示',
                        content: 'Confirm Program',
                        onOk() {
                            return new Promise<void>((resolve, reject) => {
                                controllingConfirmProgram({ confirm: true, program }).then(data => {
                                    refreshFetch(1);
                                    resetTableSelectData();
                                    message.success('Confirmed successfully')
                                    resolve();
                                }).catch(e => {
                                    reject(e);
                                })
                            })
                        },
                        onCancel() {
                            console.log('Cancel');
                        }
                    });
            }

            const handlerSearchClick = (): void => {
                Object.assign(queryParams, {year: year.value, qm: qm.value})
                resetTableSelectData();
                refreshFetch(1);
            }

            // const handlerSettingClick = (): void => {
            //     const bu = buOptions.value.map((item: any) => {
            //         if (queryParams.bu.includes(item.id)) {
            //             return { code: item.id, name: item.name }
            //         }
            //     }).filter((item: any) => item.id);
            //     const status = programStatusOptions.value.filter(item => queryParams.status.includes(item.id));
            //     const data = {
            //         bu: bu,
            //         make: makeOptions.value.filter((item: any) => queryParams.make.includes(item.name)),
            //         brand: brandOptions.value.filter((item: any) => queryParams.brand.includes(item.name)),
            //         typeClass: typeClassOptions.value.filter((item: any) => queryParams.typeClass.includes(item.name)),
            //         // periodFrom: queryParams.periodFrom,
            //         // periodTo: queryParams.periodTo,
            //         year: year.value,
            //         qm: qm.value,
            //         status,
            //         name: queryParams.name,
            //         code: queryParams.code
            //     }
            //     setSearchValue({ selJson: JSON.stringify(data), type: 'controllingProgramApprove' }).then(res => {
            //         console.log(res);
            //     })
            // }
            const handlerSettingClick = (): void => {
                
                setSearchValue({ selJson: JSON.stringify(Object.assign(queryParams, {year: year.value, qm: qm.value})), type: 'controllingProgramApprove' }).then(res => {
                    console.log(res);
                })
            }
            const getColl = () => {
                return new Promise((resolve, reject) => {
                    getSearchValue({ type: 'controllingProgramApprove'  }).then(res => {
                        if (res.length == 0) {
                            message.error('没有收藏的内容');
                            return;
                        }
                        searchValueList.value = res.map((item: any) => {
                            const obj = JSON.parse(item.selJson)
                            obj.id = item.id
                            return obj
                        });
                        resolve(res)
                    }).catch((e) => {
                        reject(e)
                    })
                })
                
            }
            const handlerCollectionClick = (): void => {
                getColl().then(() => {
                    visibleCollection.value = true;
                })
            }

            const handlerSearchValueConfirm = (value: CollectionProps): void => {
                Object.assign(queryParams, value)
                year.value = value.year
                qm.value = value.qm
                // const bu = value.bu.map(item => item.code);
                // const make = value.make.map(item => item.make);
                // const brand = value.brand.map(item => item.brand);
                // const typeClass = value.typeClass.map(item => item.typeClass);
                // const status = value.status.map(item => item.id);
                // Object.assign(queryParams, { bu, make, brand, typeClass, status, name: value.name, code: value.code, periodFrom: value.periodFrom, periodTo: value.periodTo });
                refreshFetch(1);
            }

            // const handlerPeriodChange = (date: string[]): void => {
            //     queryParams.periodFrom = date[0];
            //     queryParams.periodTo = date[1];
            // }

            const handlerClickProgramName = (record: ProgramApproveProps, index: number): void => {
                // visible.value = true;
                programPreviewData.programId = record.programId;
                programPreviewData.programPeriod = `${moment(record.periodFrom).format('YYYY.MM')}-${moment(record.to).format('YYYY.MM')}`;
                // router.push({
                //   path: "/wordingbudget",
                //   query: {
                //     programId: programPreviewData.programId,
                //     programPeriod: programPreviewData.programPeriod
                //   }
                // });
                const params = {
                    programId: programPreviewData.programId,
                    programPeriod: programPreviewData.programPeriod,
                    programName: `${record.programName}(${record.programCode})`
                }
                sessionStorage.setItem('previewPgmId', record.programId)
                visiblePreview.value = true
                // sessionStorage.setItem('paramsWording', JSON.stringify(params))
                // window.open(`${window.location.origin}/#/wordingbudget`)
                handlerRowClick(index)
            }
            // const handlerClickProgramName = (record: ProgramApproveProps, index: number): void => {
            //     // visible.value = true;
            //     programPreviewData.programId = record.programId;
            //     programPreviewData.programPeriod = `${moment(record.periodFrom).format('YYYY.MM')}-${moment(record.to).format('YYYY.MM')}`;
            //     // router.push({
            //     //   path: "/wordingbudget",
            //     //   query: {
            //     //     programId: programPreviewData.programId,
            //     //     programPeriod: programPreviewData.programPeriod
            //     //   }
            //     // });
            //     const params = {
            //         programId: programPreviewData.programId,
            //         programPeriod: programPreviewData.programPeriod,
            //         programName: `${record.programName}(${record.programCode})`
            //     }
            //     sessionStorage.setItem('paramsWording', JSON.stringify(params))
            //     window.open(`${window.location.origin}/#/wordingbudget`)
            //     handlerRowClick(index)
            // }
            const handleControllingStatus = (record: ProgramApproveProps) => {
                controllingModifyStatus({programId: record.programId}).then((res) => {
                    record.controllingStatus = res.status
                })
            }

            onMounted(() => {
                initVehicleBu()
                tableHeightRef.value = tableHeight
            })

            return {
                //query Params
                ...toRefs(queryParams),
                isOpen,
                handleCollapse,
                //table
                columns,
                data,
                rowSelection,
                tableHeight,
                currentClickRowIndex,

                pagination,

                refreshFetch,
                handlerResetBen,

                //分页处理
                handlerPaginationChange,
                handlerShowSizeChange,

                //事件处理
                handlerReturnClick,
                handlerConfirmClick,
                handlerSearchClick,
                handlerSettingClick,
                handlerCollectionClick,
                // handlerPeriodChange,
                handlerClickProgramName,

                //select options
                buOptions,
                brandOptions,
                typeClassOptions,
                programStatusOptions,
                makeOptions,

                //typeClass搜索配置
                filterOption,

                // moment
                moment,

                //wording
                visible,
                programPreviewData,

                //设置收藏
                visibleCollection,
                searchValueList,
                handlerSearchValueConfirm,
                ...toRefs(commentsObj),
                handleShowComments,
                handleControllingStatus,

                //year q/m
                year,
                qm,
                openYear,
                handlerOpenChange,
                handlerPanelChange,
                qmOptions,
                SHOW_PARENT,
                visiblePreview,
                handleShowLog,
                handleFocus,
                getColl
            }
        }
    })
