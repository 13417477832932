
import { defineComponent, PropType } from 'vue';
import { CommentProps } from '@/views/Approve/SalesRoleApprove/ProgramHome/types'
export default defineComponent({
    props: {
        callBackTextVisible: {
            type: Boolean,
            default: false
        },
        comments: {
            type: Array as PropType<CommentProps[]>,
            default: () => []
        }
    },
    setup(props, { emit }) {
        // 隐藏comments弹窗
        const hideTextModal = () => {
            emit('update:callBackTextVisible', false)
        }
        
        return {
            hideTextModal
        }
    }
})
